import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'
import ButtonOrangeArrow, {
  Arrow as ButtonOrangeArrowArrow,
} from 'components/elements/ButtonOrangeArrow'

const StyledUspBig = styled.section``

const UspBigItem = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
`

const UspBigItemIcon = styled(Image)`
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    top: -90px;
    height: 180px;
  }

  @media (max-width: 991px) {
    top: -50px;
    height: 100px;
  }

  @media (max-width: 767px) {
    top: -20px;
    height: 75px;
  }
`

const UspBigItemDescription = styled.div`
  text-align: center;

  & .button-orange-arrow {
    & button {
      display: block;

      &:hover svg {
        left: 0;
        top: 10px;
      }
    }

    & svg {
      margin: 0.75rem auto;
      display: block;
      transform: rotate(90deg);
    }
  }
`

const UspBigItemDescriptionMore = styled(motion.div)`
  overflow: hidden;
`

interface UspBigProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_UspBig
}

const UspBig: React.FC<UspBigProps> = ({ fields }) => {
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null)

  return (
    <StyledUspBig className="my-1 my-lg-5">
      <div className="container py-5">
        <div className="pb-lg-5">
          <ParseContent content={fields?.description} />
        </div>
        <div className="pt-md-5 mt-5 row lex-grow-1 justify-content-center">
          {fields?.usp?.map((edge, index: number) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="col-12 col-md-4 mb-5 mb-lg-4 flex-grow-1"
            >
              <UspBigItem className="h-100">
                <UspBigItemIcon image={edge?.icon} alt="Kennisgebied" />
                <ParseContent
                  className="mt-md-n4 mt-lg-n5 px-3 px-md-4 pb-4"
                  content={edge?.description}
                />
                <UspBigItemDescription className="mt-1 mb-3">
                  <ButtonOrangeArrow to="/" isCustom>
                    <button
                      type="button"
                      onClick={() => {
                        if (index === activeIndex) {
                          setActiveIndex(null)
                        } else {
                          setActiveIndex(index)
                        }
                      }}
                    >
                      <span>{edge?.readMoreText}</span>
                      <ButtonOrangeArrowArrow />
                    </button>
                  </ButtonOrangeArrow>
                </UspBigItemDescription>
                <UspBigItemDescriptionMore
                  animate={index === activeIndex ? 'open' : 'closed'}
                  variants={{
                    open: {
                      height: 'auto',
                    },
                    closed: {
                      height: 0,
                    },
                  }}
                >
                  <ParseContent
                    className="px-3 px-md-4 pb-4"
                    content={edge?.descriptionOpen}
                  />
                </UspBigItemDescriptionMore>
              </UspBigItem>
            </div>
          ))}
        </div>
      </div>
    </StyledUspBig>
  )
}

export default UspBig
